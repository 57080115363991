define("discourse/plugins/discourse-sentry/discourse/initializers/discourse-sentry", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const sentryBrowserVersion = '5.19.1';
  var _default = _exports.default = {
    name: 'discourse-sentry',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.8.24', () => {
        const src = `https://browser.sentry-cdn.com/${sentryBrowserVersion}/bundle.min.js`;
        const enabled = Discourse.SiteSettings.discourse_sentry_enabled;
        const dsn = Discourse.SiteSettings.discourse_sentry_dsn;
        if (!enabled || !dsn) {
          return;
        }
        const script = document.createElement('script');
        script.onload = () => {
          window.Sentry.init({
            dsn
          });
          const currentUser = Discourse.User.current();
          if (currentUser) {
            const {
              id,
              username
            } = currentUser;
            window.Sentry.configureScope(scope => {
              scope.setUser({
                id,
                username
              });
            });
          }
        };
        script.src = src;
        document.head.appendChild(script);
      });
    }
  };
});